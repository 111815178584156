/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { makeStyles } from "@mui/styles";
import bg from "../../../assets/jpg/bg13.jpg";

export default makeStyles(({ palette, functions }) => {
  // eslint-disable-next-line
  const { gradients } = palette;
  // eslint-disable-next-line
  const { linearGradient, rgba } = functions;

  return {
    pageBackgounde: {
      // overflowY: "hidden",
      overflowX: "hidden",
      backgroundImage: () =>
        `${linearGradient(
          rgba(gradients.dark.main, 1),
          rgba(gradients.dark.main, 0.6)
        )}, url(${bg})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      width: "100vw",
      height: "100vh",
    },
  };
});
