/* eslint-disable */
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Pusher from "pusher-js";
import SuiBox from "components/SuiBox";
// import PageLayout from "examples/LayoutContainers/PageLayout";
// eslint-disable-next-line
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import DefaultNavbar from "examples/Navbars/DefaultNavbar/styles/defaultNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import FriendReferralPopup from "layouts/member/friend-referral-popup";

import SuiTypography from "components/SuiTypography";
import breakpoints from "assets/theme/base/breakpoints";
// Soft UI Dashboard PRO React page layout routes
// import pageRoutes from "pageRoutes";
import { Card, Dialog, DialogContent, Grid } from "@mui/material";

import { Slide } from "react-slideshow-image";

import "react-slideshow-image/dist/styles.css";
import { useHistory } from "react-router-dom";
// Custom styles for the BaiseLayout
import { ResponseAlert } from "components/ResponseAlert";
import { CheckBalance } from "api/member";
import appbarlogo from "assets/png/appbarlogo.png";
import power from "assets/png/power.png";
import login from "assets/png/login.png";

import DotLoader from "react-spinners/DotLoader";

import { useAuth } from "auth-context/auth.context";
import CommissionPopup from "layouts/member/commission-popup";
import styles from "./styles";

import MemberBalance from "../info/member-balance";
// import TransactionMenu from "../info/transaction-menu";

// import bfplaycard3 from "../../../assets/jpg/bfplaycard3.jpg";
// import ufaplaycard2 from "../../../assets/jpg/ufaplaycard.jpg";
// import lotteryplaycard from "../../../assets/jpg/lotteryplaycard.jpg";

import gameufa from "../../../assets/png/gameufa.png";
import gamebetflix2 from "../../../assets/png/gamebetflix2.png";
import gamelottery from "../../../assets/png/gamelottery.png";
import BottomMenus from "../BottomNavigation/bottom-menus";
import AgentCircle from "../agent/circle";
import { TranferAll } from "api/tranfer";
import { EligibleTranfer } from "api/tranfer";
import Marquee from "react-fast-marquee";

var listOfImages = [];

console.log("location", window.location);

const slideImages = [
  {
    url: `../../../assets/slide/s1.png`,
    caption: "Slide 1",
  },
  {
    url: `../../../assets/slide/s2.png`,
    caption: "Slide 2",
  },
  {
    url: `../../../assets/slide/s3.png`,
    caption: "Slide 3",
  },
];

Pusher.logToConsole = false;
const pusherKey = process.env.REACT_APP_PUSHER_KEY;
const pusher = new Pusher(pusherKey, {
  cluster: "ap1",
});
let member = localStorage.getItem("user");
member = JSON.parse(member);

const memberuid = member?.member_uid;
console.log("dashboard-memberuid", memberuid);

function Default() {
  const [balance, setBalance] = useState();
  const [betflixBalance, setBFBalance] = useState();
  const [ufaBalance, setUFABalance] = useState();
  const [lottoBalance, setLottoBalance] = useState();
  const [isLoading, setLoading] = useState(false);
  const [friendRefPopup, setFriendRefPopup] = useState(false);
  const [comPopup, setComPopup] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const [isTranfer, setTranfer] = useState(false);
  const [imgSlide, setImgSlide] = useState([]);
  const [rank, setRank] = useState();
  const classes = styles();

  const { logout } = useAuth();
  const history = useHistory();

  function handleFriendReferral() {
    setFriendRefPopup(true);
  }
  function handleCommission() {
    setComPopup(true);
  }

  async function handleCheckBalance() {
    setLoading(true);
    try {
      const response = await CheckBalance();
      console.log("dashboard-response", response);
      setLoading(false);
      const status = response?.data?.status;
      if (status === "success") {
        setBalance(response.data?.data?.balance);
        setBFBalance(response.data?.data?.betflix_balance);
        setUFABalance(response.data?.data?.ufa_balance);
        setLottoBalance(response.data?.data?.lottery_balance);
        setRank(response.data?.data?.rank);
      } else {
        ResponseAlert(response);
      }
    } catch (err) {
      console.log("dashboard-catch-err", err.response);
      setLoading(false);
      ResponseAlert(err.response);
    }
  }

  async function handleTranferOut() {
    setTranfer(true);
    try {
      const response = await EligibleTranfer();

      if (response?.data?.status === "success") {
        if (response?.data?.data?.eligible) {
          const res = await TranferAll();
          setTranfer(false);
          console.log("handleTranferOut-TranferAll", res);
          if (res?.data?.status === "success") {
            handleCheckBalance();
          }
        } else {
          setTranfer(false);
          const sec = response?.data?.data?.wait;
          const wait = 5 - Number(sec);
          console.log("handleTranferPopup-wait", wait);
          alert(`คุณลูกค้าสามารถโยกเงินได้อีกครั้งในอีก ${wait} วินาที`);
        }
      } else {
        setTranfer(false);
      }
    } catch (e) {
      setTranfer(false);
    }
  }

  async function handleLogout() {
    try {
      await logout();
      // window.location.reload();
      history.push(`/sign-in`);
    } catch (err) {
      console.log("handleLogout-catch-err", err?.response);
    }
  }

  function importAll(r) {
    console.log("importAll-r", r.keys());
    // const allImg = r.keys();

    // const imgs = allImg.reduce((a, b) => {
    //   console.log("importAll-r", a);
    //   console.log("importAll-r", b);
    //   const path = b.split("./");
    //   a.push({
    //     url: `../../../assets/slide/${path[1]}`,
    //     caption: path[1],
    //   });
    //   return a;
    // }, []);

    // return imgs;

    return r.keys().map(r);
  }

  useEffect(() => {
    console.log("dashboard-useEffect-props");
    handleCheckBalance();
    const loadSlide = importAll(
      require.context("../../../assets/slide", false, /\.(png|jpe?g|svg)$/)
    );
    setImgSlide(loadSlide);
    if (window.innerWidth < breakpoints.values.lg) {
      setMobileView(true);
    } else {
      setMobileView(false);
    }
  }, []);

  if (member?.member_uid) {
    const channel = pusher.subscribe(memberuid);
    channel.bind(
      "creditbalance",
      (data) => {
        console.log("dashboard-pusher-data", data);
        handleCheckBalance();
      },
      channel.unbind("creditbalance")
    );
  }

  console.log("dashboard-listOfImages", listOfImages);

  return (
    <DashboardLayout>
      <Dialog
        PaperProps={{
          style: {
            backgroundColor: "rgba(255, 255, 255, 0)",
            boxShadow: "none",
          },
        }}
        // fullWidth
        open={isTranfer}
      >
        <DialogContent>
          <SuiBox display="flex" justifyContent="center">
            <DotLoader color="#ffffff" />
          </SuiBox>
        </DialogContent>
      </Dialog>
      <SuiBox display="flex" height="100vh">
        {friendRefPopup && <FriendReferralPopup onClose={() => setFriendRefPopup(false)} />}
        {comPopup && <CommissionPopup onClose={() => setComPopup(false)} />}
        <Grid container spacing={1}>
          {!mobileView && <Grid item xs={12} lg={3} />}
          <Grid item xs={12} lg={6}>
            <SuiBox display="flex" flexDirection="column">
              <Card
                style={{
                  backgroundColor: mobileView ? "transparent" : "#0000002e",
                }}
              >
                <SuiBox display="flex" flexDirection="column" p={3}>
                  <SuiBox display="flex" justifyContent="space-between">
                    <SuiBox
                      component="img"
                      src={appbarlogo}
                      alt={appbarlogo}
                      width="30%"
                      display="inherit"
                      mt={-2}
                    />
                    {member?.member_uid ? (
                      <SuiBox
                        component="img"
                        src={power}
                        alt={login}
                        borderRadius="lg"
                        display="inherit"
                        height="5vh"
                        mt={-1.5}
                        onClick={() => handleLogout()}
                      />
                    ) : (
                      <SuiBox
                        component="img"
                        src={login}
                        alt={login}
                        borderRadius="lg"
                        display="inherit"
                        height="5vh"
                        mt={-1.5}
                        onClick={() => history.push(`/sign-in`)}
                      />
                    )}
                  </SuiBox>
                  <SuiBox>
                    <Marquee>
                      <SuiTypography component="label" variant="caption" textColor="white">
                        ❗❗แจ้งเปลี่ยนช่องทางการติดต่อแอดมิน Line ID:{" "}
                        <Link
                          target="_blank"
                          style={{ color: "#55acee" }}
                          to={{ pathname: `${process.env.REACT_APP_LINE_URL}` }}
                        >
                          {`${process.env.REACT_APP_LINE_ID}`}
                        </Link>{" "}
                        หรือ คลิ๊ก{" "}
                        <Link
                          to={{ pathname: `${process.env.REACT_APP_LINE_URL}` }}
                          target="_blank"
                          style={{ color: "#55acee" }}
                        >
                          ไลน์หลัก
                        </Link>
                        ,{" "}
                        <Link
                          to={{ pathname: `${process.env.REACT_APP_LINE_URL_2}` }}
                          target="_blank"
                          style={{ color: "#55acee" }}
                        >
                          ไลน์สำรอง
                        </Link>{" "}
                        - กรุณาแอดไลน์ใหม่เพื่อความสะดวกในการติดต่อ{" "}
                        <Link
                          target="_blank"
                          style={{ color: "transparent" }}
                          to={{ pathname: `${process.env.REACT_APP_LINE_URL}` }}
                        >
                          {"-----"}
                        </Link>{" "}
                      </SuiTypography>
                    </Marquee>
                  </SuiBox>
                  {member?.member_uid && (
                    <SuiBox mt={-2}>
                      <MemberBalance
                        isLoading={isLoading}
                        allBalance={{
                          credit_balance: balance,
                          ufa_balance: ufaBalance,
                          betflix_balance: betflixBalance,
                          lottery_balance: lottoBalance,
                        }}
                        balance={balance}
                        rank={rank}
                        onFetchCreditBalance={() => handleCheckBalance()}
                        onFriendReferral={() => handleFriendReferral()}
                        onCommission={() => handleCommission()}
                        onTranferOut={() => handleTranferOut()}
                      />
                    </SuiBox>
                  )}
                  <Grid container spacing={1} justifyContent="center" mt={-4}>
                    <Grid item xs={4}>
                      <SuiBox
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        // backgroundColor="rgba(0, 0, 0, 0.5)"
                        backgroundGradient
                        borderRadius="lg"
                      >
                        <AgentCircle
                          isLoading={isLoading}
                          agent="ufa"
                          image={gameufa}
                          balance={balance}
                          agentBalance={ufaBalance}
                          onFetchBalance={() => handleCheckBalance()}
                        />
                      </SuiBox>
                    </Grid>
                    <Grid item xs={4}>
                      <SuiBox
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        // backgroundColor="rgba(255, 255, 255, 0.3)"
                        backgroundGradient
                        borderRadius="lg"
                      >
                        <AgentCircle
                          isLoading={isLoading}
                          agent="betflix"
                          image={gamebetflix2}
                          balance={balance}
                          agentBalance={betflixBalance}
                          onFetchBalance={() => handleCheckBalance()}
                        />
                      </SuiBox>
                    </Grid>
                    <Grid item xs={4}>
                      <SuiBox
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        // backgroundColor="rgba(255, 255, 255, 0.3)"
                        backgroundGradient
                        borderRadius="lg"
                      >
                        <AgentCircle
                          isLoading={isLoading}
                          agent="huaydragon"
                          image={gamelottery}
                          balance={balance}
                          agentBalance={0}
                          onFetchBalance={() => handleCheckBalance()}
                        />
                      </SuiBox>
                    </Grid>
                    {/* <Grid item xs={6}>
                      <SuiBox
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        // backgroundColor="rgba(255, 255, 255, 0.3)"
                        backgroundGradient
                        borderRadius="lg"
                      >
                        <AgentCircle
                          isLoading={isLoading}
                          agent="lottery"
                          image={gamelottery}
                          balance={balance}
                          agentBalance={lottoBalance}
                          onFetchBalance={() => handleCheckBalance()}
                        />
                      </SuiBox>
                    </Grid> */}
                  </Grid>
                  <SuiBox mt={1}>
                    <div className="slide-container">
                      <Slide arrows={false}>
                        {imgSlide.map((slideImage) => {
                          return (
                            <SuiBox>
                              <SuiBox
                                component="img"
                                src={`${window.location.origin}${slideImage.default}`}
                                alt={slideImage.default}
                                borderRadius="lg"
                                width="100%"
                                display="inherit"
                                // height="50vh"
                              />
                            </SuiBox>
                          );
                        })}
                      </Slide>
                    </div>
                  </SuiBox>
                </SuiBox>
              </Card>
              <SuiBox height="75px" />
            </SuiBox>
          </Grid>
          {!mobileView && <Grid item xs={12} lg={3} />}
        </Grid>
      </SuiBox>
      {member?.token && <BottomMenus value={2} />}
    </DashboardLayout>
  );
}

export default Default;
