import { useState, useEffect } from "react";

// react-router-dom components
import { useHistory, Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import { Grid } from "@mui/material";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";
import Separator from "layouts/authentication/components/Separator";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PageLayout from "examples/LayoutContainers/PageLayout";
import ForgotPasswordPopup from "layouts/authentication/forgotPassword/forgotPasswordPopup";
import bf from "assets/jpg/bf.jpg";
import logonavbar from "assets/png/logonavbar.png";
import bflable from "assets/png/bflabel.png";
import ufalabel from "assets/png/ufalabel.png";
import huaylabel from "assets/png/huaylabel.png";
import { useAuth } from "../../../../auth-context/auth.context";

function Basic() {
  const history = useHistory();
  // const { setUser } = useAuth();
  const { login } = useAuth();
  // const { setAccessToken } = useAuth();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(undefined);
  const [isShow, setShowPassword] = useState(false);
  const [isForgotPassword, setForgotPassword] = useState(false);
  const [buttonText, setButtonText] = useState("เข้าสู่ระบบ (Login)");
  const localUser = localStorage.getItem("user");
  const user = typeof localUser === "string" ? JSON.parse(localUser) : localUser;
  console.log("login-user", user);

  useEffect(() => {
    if (user) {
      console.log("user", user);
      history.push("/dashboard");
    }
  }, []);

  const handleLogin = async (event) => {
    if (event) {
      event.preventDefault();
    }
    if (user && user.token) {
      // return history.push("/dashboard");
    }
    if (username === "") {
      setError("You must enter your username.");
      return;
    }
    if (password === "") {
      setError("You must enter your password");
      return;
    }
    setButtonText("กำลังเข้าสู่ระบบ (Logingin)");
    console.log("username", username);
    console.log("password", password);
    const loginData = { username, password };
    try {
      const response = await login(loginData);
      console.log("singin-response", response);
      if (response?.data?.status !== "success") {
        setButtonText("เข้าสู่ระบบ (Login)");
        setError(response?.data?.message);
        return;
      }
      history.push("/dashboard");
      window.location.reload();
    } catch (err) {
      console.log(err);
      setButtonText("เข้าสู่ระบบ (Login)");
      setError("There has been an error.");
    }
  };

  return (
    <PageLayout image={bf}>
      {isForgotPassword && <ForgotPasswordPopup onClose={() => setForgotPassword(false)} />}
      <Grid container spacing={3} justifyContent="center">
        <Grid
          container
          item
          xs={12}
          justifyContent="center"
          alignItems="center"
          className="text-center"
        >
          <Grid item xs={10} lg={4}>
            <SuiBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <SuiBox
                component="img"
                src={logonavbar}
                alt="message-image"
                borderRadius="lg"
                boxShadow="md"
                width="100%"
                display="inherit"
              />
              <SuiBox display="flex" justifyContent="center">
                <SuiBox
                  component="img"
                  src={bflable}
                  alt="message-image"
                  borderRadius="lg"
                  boxShadow="md"
                  width="30%"
                  display="inherit"
                />
                <SuiBox
                  ml={3}
                  component="img"
                  src={ufalabel}
                  alt="message-image"
                  borderRadius="lg"
                  boxShadow="md"
                  width="30%"
                  display="inherit"
                />
                <SuiBox
                  ml={3}
                  component="img"
                  src={huaylabel}
                  alt="message-image"
                  borderRadius="lg"
                  boxShadow="md"
                  width="30%"
                  display="inherit"
                />
              </SuiBox>
            </SuiBox>
          </Grid>
        </Grid>
        <Grid item xs={10} lg={4} mt={2}>
          <Card
            className="overflow-visible"
            style={{
              backgroundColor: "rgba(11, 8, 37, 0.5)",
              width: "100%",
              py: 2,
            }}
          >
            <SuiBox px={3} mt={3} mb={1} textAlign="center">
              <SuiTypography variant="h4" fontWeight="medium" textColor="white">
                {user && user.token ? "คุณได้เข้าสู่ระบบแล้ว" : "เข้าสู่ระบบ"}
              </SuiTypography>
            </SuiBox>

            <SuiBox px={3} mt={2}>
              <SuiBox component="form" role="form">
                {user && user.token ? null : (
                  <SuiBox>
                    <SuiBox mb={2}>
                      <SuiTypography component="label" variant="caption" textColor="white">
                        ยูสเซอร์หรือเบอร์โทรศัพท์ (Username or Phonenumber)
                      </SuiTypography>
                      <SuiInput
                        defaultValue={username}
                        onChange={(event) => {
                          setUsername(event.target.value);
                          setError(undefined);
                        }}
                        type="text"
                        placeholder="ยูสเซอร์หรือเบอร์โทรศัพท์"
                      />
                    </SuiBox>

                    <SuiBox>
                      <SuiTypography component="label" variant="caption" textColor="white">
                        รหัสผ่าน (Password)
                      </SuiTypography>
                      <SuiBox>
                        <SuiInput
                          defaultValue={password}
                          onChange={(event) => {
                            setPassword(event.target.value);
                            setError(undefined);
                          }}
                          type={isShow ? "text" : "password"}
                          placeholder="รหัสผ่าน"
                          withIcon={{
                            icon: isShow ? <VisibilityIcon /> : <VisibilityOffIcon />,
                            direction: "right",
                            onIconClick: () => setShowPassword(!isShow),
                          }}
                        />
                      </SuiBox>
                    </SuiBox>
                    <SuiTypography component="label" variant="caption" textColor="error">
                      {error}
                    </SuiTypography>
                  </SuiBox>
                )}
                <SuiBox
                  mt={1}
                  textAlign="right"
                  sx={{ cursor: "pointer" }}
                  onClick={() => setForgotPassword(true)}
                >
                  <SuiTypography variant="button" textColor="light" fontWeight="bold">
                    ลืมรหัสผ่าน?
                  </SuiTypography>
                </SuiBox>
                <SuiBox mt={4} mb={3}>
                  <SuiButton
                    sx={{ mb: 1 }}
                    type="submit"
                    onClick={(event) => handleLogin(event)}
                    variant="gradient"
                    buttonColor="info"
                    fullWidth
                    circular
                  >
                    {buttonText}
                  </SuiButton>
                  <Separator />

                  <SuiButton
                    component={Link}
                    to="/sign-up"
                    sx={{ mt: 1 }}
                    variant="gradient"
                    buttonColor="success"
                    fullWidth
                    circular
                  >
                    สมัครสมาชิก (Register)
                  </SuiButton>
                </SuiBox>
              </SuiBox>
            </SuiBox>
          </Card>
        </Grid>
      </Grid>
    </PageLayout>
  );
}

export default Basic;
