import { useEffect, useState } from "react";

import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiInput from "components/SuiInput";
import SuiTypography from "components/SuiTypography";
import SuiSelect from "components/SuiSelect";
import { banks } from "utils/banks";
import { campaigns } from "utils/campaigns";

function MemberInfoForm({ onSetMember }) {
  const [member, setMember] = useState();
  const [error, setError] = useState();
  const [bankSelect, setBankSelect] = useState();
  const [campaignSelect, setCampaignSelect] = useState();
  const isBlurInput = true;

  function handleSetMember(key, value) {
    const newMember = { ...member };
    newMember[key] = value;
    setError(undefined);
    setMember(newMember);
    onSetMember(newMember);
  }

  function handleChangeBank(bank) {
    const newMember = { ...member };
    newMember.bank_uid = bank?.bank_uid;
    newMember.bank_code = bank?.bank_code;
    setBankSelect(bank);
    setMember(newMember);
    onSetMember(newMember);
  }

  function handleChangeCampaign(campaign) {
    const newMember = { ...member };
    newMember.campaign_uid = campaign?.campaign_uid;
    setCampaignSelect(campaign);
    setMember(newMember);
    onSetMember(newMember);
  }

  useEffect(() => {
    const wls = window?.location?.search;
    if (wls.includes("?ref=")) {
      const friendCamp = campaigns.find((f) => f.campaign_code === "friend");
      const newMember = { ...member };
      newMember.campaign_uid = friendCamp?.campaign_uid;
      setCampaignSelect(friendCamp);
      setMember(newMember);
      onSetMember(newMember);
    }
    if (wls.includes("?campaign=")) {
      const spwls = wls.split("?campaign=");
      const camp = spwls[1];
      const friendCamp = campaigns.find((f) => f.campaign_code === camp);
      const newMember = { ...member };
      newMember.campaign_uid = friendCamp?.campaign_uid;
      setCampaignSelect(friendCamp);
      setMember(newMember);
      onSetMember(newMember);
    }
  }, []);

  console.log("MemberInfoForm-member", member);

  return (
    <SuiBox>
      <SuiBox component="form" role="form">
        <SuiBox mb={2}>
          <SuiBox>
            <SuiTypography component="label" variant="caption" fontWeight="bold" textColor="white">
              ชื่อ *
            </SuiTypography>
            <SuiInput
              type="text"
              value={member?.first_name ?? ""}
              onChange={(event) => {
                handleSetMember("first_name", event.target.value);
              }}
              placeholder="ชื่อ"
              size="small"
            />
          </SuiBox>
          <SuiBox sx={{ mt: 1 }}>
            <SuiTypography component="label" variant="caption" fontWeight="bold" textColor="white">
              นามสกุล *
            </SuiTypography>
            <SuiInput
              type="text"
              value={member?.last_name ?? ""}
              onChange={(event) => {
                handleSetMember("last_name", event.target.value);
              }}
              placeholder="นามสกุล"
              size="small"
            />
          </SuiBox>

          <SuiBox sx={{ mt: 1 }}>
            <SuiTypography component="label" variant="caption" fontWeight="bold" textColor="white">
              ธนาคาร *
            </SuiTypography>
            <SuiBox backgroundColor="white" borderRadius="md">
              <SuiSelect
                value={bankSelect ?? null}
                placeholder="ธนาคาร"
                options={banks}
                onChange={(item) => handleChangeBank(item)}
                blurInputOnSelect={isBlurInput}
                size="small"
              />
            </SuiBox>
          </SuiBox>
          <SuiBox sx={{ mt: 1 }}>
            <SuiTypography component="label" variant="caption" fontWeight="bold" textColor="white">
              เลขที่บัญชี *
            </SuiTypography>
            <SuiInput
              type="number"
              value={member?.member_bank_number ?? ""}
              onChange={(event) => {
                handleSetMember("member_bank_number", event.target.value);
              }}
              placeholder="เลขที่บัญชี"
              size="small"
            />
          </SuiBox>
          <SuiBox sx={{ mt: 1 }}>
            <SuiTypography component="label" variant="caption" fontWeight="bold" textColor="white">
              ไอดีไลน์
            </SuiTypography>
            <SuiInput
              value={member?.line_id ?? ""}
              onChange={(event) => {
                handleSetMember("line_id", event.target.value);
              }}
              placeholder="ไอดีไลน์"
              size="small"
            />
          </SuiBox>
          {(!window?.location?.search.includes("?ref=") ||
            !window?.location?.search.includes("?campaign=")) && (
            <SuiBox sx={{ mt: 1 }}>
              <SuiTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                textColor="white"
              >
                รู้จักเราจาก *
              </SuiTypography>
              <SuiBox backgroundColor="white" borderRadius="md">
                <SuiSelect
                  value={campaignSelect ?? null}
                  onChange={(item) => handleChangeCampaign(item)}
                  placeholder="แคมเปญ"
                  menuPlacement="top"
                  options={campaigns}
                  blurInputOnSelect={isBlurInput}
                  size="small"
                />
              </SuiBox>
            </SuiBox>
          )}
        </SuiBox>

        <SuiBox mt={2} mb={2} textAlign="center">
          <h6
            style={{
              fontSize: ".7em",
              color: "red",
              textAlign: "center",
              fontWeight: 400,
              transition: ".2s all",
            }}
          >
            {error}
          </h6>
        </SuiBox>
      </SuiBox>
    </SuiBox>
  );
}

MemberInfoForm.defaultProps = {
  onSetMember: () => {},
};

// Typechecking props of the SuiAlert
MemberInfoForm.propTypes = {
  onSetMember: PropTypes.func,
};

export default MemberInfoForm;
