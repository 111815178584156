/* eslint-disable */
import { useState, useEffect, useMemo } from "react";
import {
  Route,
  Switch,
  Redirect,
  useLocation,
  useHistory,
  Link as ReactLink,
} from "react-router-dom";
import { create } from "jss";
import rtl from "jss-rtl";
import { StylesProvider, jssPreset } from "@mui/styles";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { Container, Button, Link } from "react-floating-action-button";
// import Icon from "@mui/material/Icon";
import Swal from "sweetalert2";
import SuiBox from "components/SuiBox";
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";
import routes from "routes";
import breakpoints from "assets/theme/base/breakpoints";
import { useSoftUIController } from "context";
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import ProtectedRoute from "components/ProtectedRoute/index";
import Tooltip from "@mui/material/Tooltip";
import axios from "./api/index";
import LINEFAB from "./assets/png/LINEFAB.png";
import LuckyWheel from "./assets/gif/lucky-wheel.gif";
import announceImg from "./assets/jpg/announceImg.jpg";
import SuiTypography from "components/SuiTypography";
import LINEFABSM from "./assets/png/linefeb-sm.png";

export default function App() {
  const [controller, dispatch] = useSoftUIController();
  // eslint-disable-next-line
  const { miniSidenav, direction, layout, openConfigurator } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();
  const [isAnn, setAnn] = useState(false);
  const [isOpenFab, setOpenFab] = useState(false);

  const [mobileView, setMobileView] = useState(false);
  const history = useHistory();
  if (process.env.NODE_ENV === "production") {
    console.log = function () {};
  }
  // JSS presets for the rtl
  const jss = create({
    plugins: [...jssPreset().plugins, rtl()],
  });

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      prepend: true,
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      dispatch({ type: "MINI_SIDENAV", value: false });
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      dispatch({ type: "MINI_SIDENAV", value: true });
      setOnMouseEnter(false);
    }
  };

  // // Change the openConfigurator state
  // const handleConfiguratorOpen = () => {
  //   dispatch({ type: "OPEN_CONFIGURATOR", value: !openConfigurator });
  // };

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);

    // Swal.fire({
    //   title: "📢  ประกาศสำคัญ BETFLIX29",
    //   icon: "info",
    //   html: `ขณะนี้เว็ปไซด์ปิดปรับปรุงระบบ คุณลูกค้าจะไม่สามารถทำรายการ ฝาก-ถอน ได้ในขณะนี้
    //   จนกว่าระบบจะอัพเดทเสร็จสมบูรณ์ จะรีบแจ้งให้ทราบค่ะ`,
    //   allowEscapeKey: false,
    //   allowOutsideClick: false,
    //   showCancelButton: false,
    //   // showConfirmButton: false,
    // });
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const localUser = localStorage.getItem("user");
  const user = typeof localUser === "string" ? JSON.parse(localUser) : localUser;
  const accessToken = localStorage.getItem("accessToken");
  axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }
      if (route.route) {
        const Component = route.component;
        if (route.protected || route.route.protected) {
          return (
            <ProtectedRoute exact path={route.route} component={route.component} key={route.key} />
          );
        }
        return (
          <Route
            exact
            path={route.route}
            render={() => <Component message="hi" />}
            key={route.key}
          />
        );
      }

      return null;
    });

  const handleClickAnnouce = () => {
    setAnn(false);
    localStorage.setItem("isAnnounce", false);
    window.open(`${process.env.REACT_APP_LINE_URL}`, "_blank");
  };

  useEffect(() => {
    console.log("App - useEffect - isAnn", isAnn);
    if (window.innerWidth < breakpoints.values.lg) {
      setMobileView(true);
    } else {
      setMobileView(false);
    }
    const isAnnounce = localStorage.getItem("isAnnounce");
    if (isAnnounce === null) {
      setAnn(true);
    }
  }, [isAnn]);

  const wheelButton = (
    <SuiBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.5rem"
      height="3.5rem"
      backgroundColor="white"
      boxShadow="sm"
      borderRadius="50%"
      position="fixed"
      left="0.75rem"
      bottom={user && user?.token ? "4.2rem" : "0.75rem"}
      zIndex={99}
      customClass="cursor-pointer"
      onClick={() => history.push(`/wheel`)}
    >
      <SuiBox
        component="img"
        src={LuckyWheel}
        alt="message-image"
        borderRadius="lg"
        boxShadow="md"
        width="100%"
        display="inherit"
      />
    </SuiBox>
  );

  const lineButton = (
    <Container styles={{ right: "-0.5rem", bottom: user && user?.token ? "3rem" : "0.75rem" }}>
      <Link styles={{ backgroundColor: "transparent", color: "transparent" }}>
        <Tooltip title="ไลน์หลัก" placement="left">
          <SuiBox
            component="img"
            src={LINEFABSM}
            alt="message-image"
            borderRadius="lg"
            boxShadow="md"
            width="100%"
            display="inherit"
            onClick={() => {
              window.open(`${process.env.REACT_APP_LINE_URL}`, "_blank");
            }}
          />
        </Tooltip>
      </Link>
      <Link styles={{ backgroundColor: "transparent", color: "transparent" }}>
        <Tooltip title="ไลน์สำรอง" placement="left">
          <SuiBox
            component="img"
            src={LINEFABSM}
            alt="message-image"
            borderRadius="lg"
            boxShadow="md"
            width="100%"
            display="inherit"
            onClick={() => {
              window.open(`${process.env.REACT_APP_LINE_URL_2}`, "_blank");
            }}
          />
        </Tooltip>
      </Link>
      <Button rotate={false} styles={{ backgroundColor: "transparent", color: "transparent" }}>
        <Tooltip title="ติดต่อแอดมิน" placement="left">
          <SuiBox
            component="img"
            src={LINEFAB}
            alt="message-image"
            borderRadius="lg"
            boxShadow="md"
            width="100%"
            display="inherit"
          />
        </Tooltip>
      </Button>
    </Container>
  );

  const Announcement = (
    <SuiBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      backgroundColor="rgba(0, 0, 0, 0.5)"
      boxShadow="sm"
      position="fixed"
      zIndex={99}
      height={`${window.innerHeight}px`}
      width={`${window.innerWidth}px`}
    >
      {mobileView ? (
        <SuiBox
          component="img"
          src={announceImg}
          alt="announceImg"
          borderRadius="lg"
          boxShadow="md"
          display="inherit"
          customClass="cursor-pointer"
          onClick={() => handleClickAnnouce()}
          width="90%"
        />
      ) : (
        <SuiBox
          component="img"
          src={announceImg}
          alt="announceImg"
          borderRadius="lg"
          boxShadow="md"
          display="inherit"
          customClass="cursor-pointer"
          onClick={() => handleClickAnnouce()}
        />
      )}
    </SuiBox>
  );

  return direction === "rtl" ? (
    <CacheProvider value={rtlCache}>
      <StylesProvider jss={jss}>
        <ThemeProvider theme={themeRTL}>
          <CssBaseline />
          {/* {isAnn && Announcement} */}
          {layout === "dashboard" && (
            <>
              <Sidenav
                routes={routes}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
              <Configurator />
            </>
          )}
          {layout === "vr" && <Configurator />}
          <Switch>
            {getRoutes(routes)}
            {!user || !user.token || user.token === "" ? (
              <Redirect from="*" to="/sign-in" />
            ) : (
              <Redirect from="*" to="/dashboard" />
            )}

            {/* <Redirect from="*" to="/dashboards" /> */}
          </Switch>
          {lineButton}
          {user?.token && wheelButton}
        </ThemeProvider>
      </StylesProvider>
    </CacheProvider>
  ) : (
    // </CacheProvider>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />

        {/* {isAnn && Announcement} */}
        {layout === "dashboard" && (
          <>
            <Sidenav
              routes={routes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <Configurator />
          </>
        )}
        {layout === "vr" && <Configurator />}
        <Switch>
          {getRoutes(routes)}
          {!user || !user.token || user.token === "" ? (
            <Redirect from="*" to="/sign-in" />
          ) : (
            <Redirect from="*" to="/dashboard" />
          )}

          {/* <Redirect from="*" to="/dashboards" /> */}
        </Switch>
        {lineButton}
        {user?.token && wheelButton}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
